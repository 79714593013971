/**
 * This context is used to manage the product categories list.
 * Next step would be to make "Products" the context, and Categories
 * a tab and entity_type of this submodule, but that is not for now.
 */
import {api, notifier} from '@bitstillery/common/app'
import {ContextProvider, ContextProviderDataGeneric} from '@bitstillery/common/lib/context'
import {merge_deep} from '@bitstillery/common/lib/utils'

import {collection} from '@/data/product_categories/list/lib/collection_prdcat'
import {
    CreateEntityResponse,
    GetProductCategoryResponse,
    GetProductCategoriesResponse,
    GetVatRateResponse,
    ProductBottleType,
} from '@/factserver_api/fact2server_api'

export enum EntityType {
    PRDCAT = 'prdcat',
}

const data = {
    alternatives: {
        disabled: false,
        items: [] as string[],
        new_item: '',
    },

    be_vat_rates: [] as GetVatRateResponse[],
    de_vat_rates: [] as GetVatRateResponse[],
    entity_artkey: null,
    entity_type: '',
    entities: {
        [EntityType.PRDCAT]: {
            artkey: null,
            cbs_code: '',
            cbs_code_gte_200: '',
            cbs_code_gte_1000: '',
            be_vat_rate_artkey: 0,
            nl_vat_rate_artkey: 0,
            de_vat_rate_artkey: 0,
            parent_artkey: null,
            priority: 0,
            is_spirit: true,
            default_product_bottle_type: ProductBottleType.HEAVY_GLASS,
            name: '',
            meta: false,
            title: '',
        },
    },
    nl_vat_rates: [] as GetVatRateResponse[],
    main_categories: [] as { artkey: number; name: string }[],
    root_artkey: null!,
    root_path: '/data/product-categories',
} satisfies ContextProviderDataGeneric

export type ListContextData = typeof data

export const context = new ContextProvider<ListContextData>({
    data,
    name: 'data.product_categories.list',
    route: {
        match: '/data/product-categories/list{/:tab_id}{/:entity_type}{/:entity_artkey}',
        root: '/data/product-categories/list/manage',
    },
    transforms: {
        bootstrap: async() => {
            const {status_code, result} = await api.get<GetVatRateResponse[]>('discover/data/vat-rates')
            if (status_code > 299) {
                return
            }
            const entity = context.data.entities[EntityType.PRDCAT]
            context.data.be_vat_rates = result.filter((vat) => vat.country_code === 'BE')
            context.data.de_vat_rates = result.filter((vat) => vat.country_code === 'DE')
            context.data.nl_vat_rates = result.filter((vat) => vat.country_code === 'NL')
            entity.be_vat_rate_artkey = context.data.be_vat_rates.at(0)?.artkey || -1
            entity.de_vat_rate_artkey = context.data.de_vat_rates.at(0)?.artkey || -1
            entity.nl_vat_rate_artkey = context.data.nl_vat_rates.at(0)?.artkey || -1

            const {result: result_categories} = await api.get<GetProductCategoriesResponse>('discover/product-categories')
            context.data.main_categories = result_categories.categories.map((cat) => {
                return {
                    artkey: cat.artkey, name: cat.name,
                }
            })
            context.bootstrap_data()
        },
        fetch_entity: async() => {
            const entity = context.data.entities[context.data.entity_type]
            const {
                status_code,
                result,
            } = await api.get<GetProductCategoryResponse>(`discover/product-categories/${context.data.entity_artkey}`)
            if (status_code > 299) {
                notifier.notify('Cannot load the Product Category')
                return
            }
            merge_deep(entity, result)
            return {collection}
        },
    },
})

export const methods = {
    async upsert_entity(entity_type, data) {
        const entity = context.data.entities[entity_type]
        entity.parent_artkey = entity.parent_artkey ? entity.parent_artkey : null
        if (!entity.artkey) {
            const {status_code} = await api.post<CreateEntityResponse>('discover/product-categories', data, true)
            if (status_code > 299) {
                notifier.notify('Cannot create the Product Category', 'warning')
                return
            }
            context.select_next(collection)
            notifier.notify('The Product Category was created.')
        } else {
            const {status_code} = await api.put(`discover/product-categories/${entity.artkey}`, data)
            if (status_code > 299) {
                notifier.notify('Cannot update the Product Category', 'warning')
                return
            }
            await collection.update_item(entity.artkey)
            notifier.notify('The Product Category was updated.')
        }
    },

}
