import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {AmountUnit, Button, CellProduct, CollectionHeader, CollectionItems, CollectionView, FieldSelect, FieldText, PanelBulk} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {SelectOption} from '@bitstillery/common/types/field'
import {api, notifier} from '@bitstillery/common/app'

import {
    GetPurchaseOrderItemsCollectionViewResponse,
    GetPurchaseOrderResponse,
    GetRelationPurchaseOrderResponse,
    MovePOIBulkRequest,
    PurchaseOrderStatus,
} from '@/factserver_api/fact2server_api'

export const collection = new CollectionProxy()

interface GetPurchaseOrderItemsCollectionViewResponseExt extends GetPurchaseOrderItemsCollectionViewResponse {
    number_of_cases_to_move: number | null
    move_to_artkey: number | null
}

enum MoveMode {
    BULK,
    SINGLE
}

export default class MovePurchaseOrderItems extends MithrilTsxComponent<unknown> {

    purchase_order_artkey: number
    watchers: type_remove_watch_function[] = []

    model = proxy({
        backend_is_moving: false,
        source_purchase_order: {} as GetPurchaseOrderResponse,
        target_artkey: null as string | null,
        bulk_target_purchase_order_artkey: null as string | null,
        bulk_target_options: [
            {value: 'NEW', label: 'To new purchase order'},
        ] as SelectOption[],
        move_mode: MoveMode.SINGLE,
    })

    target_options = [] as SelectOption[]

    columns = [
        {
            className: 'cell-group',
            name: 'Product',
            render: (row: GetPurchaseOrderItemsCollectionViewResponse) => {
                return [
                    <CellProduct {...row}/>,
                ]
            },
            width: '2fr',
        }, {
            name: 'Price per case',
            render: (row: GetPurchaseOrderItemsCollectionViewResponse) => {
                return <div className="td-group">
                    <AmountUnit
                        case_amount={row.was_bought_for}
                        case_number_of_bottles={row.number_of_bottles}
                        currency={row.was_bought_in}
                        display_currency={row.was_bought_in}
                        rate={row.bought_against_rate}
                    />
                </div>
            },
        },
        {
            name: 'Number of cases',
            render: (row: GetPurchaseOrderItemsCollectionViewResponse) => {
                return <div className="td-group">{row.number_of_cases}</div>
            },
        },
        {
            name: 'Move',
            render: (row: GetPurchaseOrderItemsCollectionViewResponseExt) => {
                const target_po_text = row.move_to_artkey ? `P${row.move_to_artkey}` : 'new purchase order'
                const button_text = `${row.number_of_cases_to_move || '? '}cs to ${target_po_text}`
                return <div className={classes('td-group', 'move-actions')}>
                    <div className='row-1'>
                        <FieldText
                            type="number"
                            model={[row, 'number_of_cases_to_move']}
                            max={row.number_of_cases}
                            min={1}
                            disabled={this.model.move_mode !== MoveMode.SINGLE}
                        />

                        <FieldSelect
                            placeholder='To new purchase order'
                            model={[row, 'move_to_artkey']}
                            options={this.target_options}
                            disabled={this.model.move_mode !== MoveMode.SINGLE}
                        />
                    </div>
                    <Button
                        disabled={this.model.backend_is_moving ||
                            this.model.move_mode !== MoveMode.SINGLE ||
                            !row.number_of_cases_to_move ||
                            row.number_of_cases_to_move > row.number_of_cases}
                        icon='arrowRight'
                        text={button_text}
                        onclick={() => this.move_poi_to_target(row)}/>
                </div>
            },
        },
    ]

    constructor() {
        super()
        if (!m.route.param('artkey')) {
            throw Error('No purchase order artkey in route.')
        } else {
            this.purchase_order_artkey = +m.route.param('artkey')
        }
    }

    onremove() {
        this.watchers?.map((unwatch) => unwatch())
    }

    async oninit() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/purchase-orders/${this.purchase_order_artkey}/items/collection-view`,
            },
            selection: {
                all: false,
                field: 'move_to_other_purchase_order',
            },
        }, undefined,
        {
            items_queried: async({result}) => {
                return result
            },
        })
        this.model.source_purchase_order = await this.fetch_purchase_order(this.purchase_order_artkey)
        this.watchers = [
            watch(this.model, 'bulk_target_purchase_order_artkey', this.on_update_bulk_target.bind(this)),
        ]
        await this.fetch_target_purchase_order_options()
    }

    async fetch_purchase_order(purchase_order_artkey: number) {
        const {status_code, result} = await api.get <GetPurchaseOrderResponse>(`discover/purchase-orders/${purchase_order_artkey}`)
        if (status_code > 299) {
            throw Error()
        }
        return result
    }

    async fetch_target_purchase_order_options() {
        const requested_purchase_order_statuses = [
            PurchaseOrderStatus.Saved,
        ]
        const relation_artkey = this.model.source_purchase_order.supplier.artkey
        const source_purchase_order_artkey = this.model.source_purchase_order.artkey
        const {status_code, result} = await api.get<GetRelationPurchaseOrderResponse[]>(
            `discover/relations/${relation_artkey}/purchase-orders?purchase_order_statuses=${requested_purchase_order_statuses.join(',')}`,
        )
        if (status_code > 299) {
            return []
        }
        this.target_options.splice(0)
        this.model.bulk_target_options.splice(1)
        result
            .filter((it) => it.artkey !== source_purchase_order_artkey)
            .map((it) => {
                return {
                    label: it.reference,
                    value: it.artkey,
                }
            })
            .forEach((it) => {
                this.target_options.push(it)
                this.model.bulk_target_options.push(it)
            })
    }

    async move_poi_to_target(row: GetPurchaseOrderItemsCollectionViewResponseExt) {
        if (!row.number_of_cases_to_move) {
            return
        }
        collection.select_one(row.artkey)

        const data = {
            target_purchase_order_artkey: row.move_to_artkey || undefined,
            source_poi_artkey: +row.artkey,
            number_of_cases_to_move: +row.number_of_cases_to_move,
        }
        this.model.backend_is_moving = true
        const {status_code, result} = await api.post(
            `discover/purchase-orders/${this.model.source_purchase_order.artkey}/move-items`, data, true)
        if (status_code > 299) {
            this.model.backend_is_moving = false
            const msg = result['detail'] ? `Cannot move items: '${result.detail}'` : 'Error moving items'
            notifier.notify(msg, 'warning')
            return
        }

        // let the user know
        if (status_code === 201) {
            notifier.notify(`Moved ${row.number_of_cases_to_move} cases to a new <a href="#!/purchase-orders/manage/${result.artkey}">purchase order P${result.artkey}</a>`)
        } else {
            notifier.notify(`Moved ${row.number_of_cases_to_move} cases to <a href="#!/purchase-orders/manage/${row.move_to_artkey}">P${row.move_to_artkey}</a>`)
        }

        // Either remove the poi when everything is moved, or refresh from backend
        if (row.number_of_cases === row.number_of_cases_to_move) {
            collection.soft_delete(row.artkey)
        } else {
            await collection.update_item(row.artkey)
        }
        // update form.
        row.number_of_cases_to_move = null

        // refresh combo
        await this.fetch_target_purchase_order_options()
        this.model.backend_is_moving = false
    }

    async on_update_bulk_target() {
        if (this.model.bulk_target_purchase_order_artkey) {
            this.start_bulk_mode()
        } else {
            this.end_bulk_mode()
        }
    }

    start_bulk_mode() {
        collection.state.selection.mode = 'select'
        this.model.move_mode = MoveMode.BULK
    }

    end_bulk_mode() {
        collection.state.selection.mode = ''
        this.model.move_mode = MoveMode.SINGLE
    }

    async move_pois_to_target() {

        const target_artkey =
            this.model.bulk_target_purchase_order_artkey === 'NEW' || this.model.bulk_target_purchase_order_artkey === null
                ? undefined : +this.model.bulk_target_purchase_order_artkey

        const all_ids = collection.state.items.map((it) => it.artkey)
        let selection = [] as number[]

        if (collection.state.selection.all) {
            selection = all_ids.filter((id) => !collection.state.selection.ids.includes(id))
        } else {
            selection = collection.state.selection.ids
        }
        const data: MovePOIBulkRequest = {
            target_purchase_order_artkey: target_artkey,
            source_poi_artkeys: selection,
        }

        this.model.backend_is_moving = true
        const {status_code, result} = await api.post(
            `discover/purchase-orders/${this.model.source_purchase_order.artkey}/move-items-in-bulk`, data, true)
        if (status_code > 299) {
            this.model.backend_is_moving = false
            const msg = result['detail'] ? `Cannot move items: '${result.detail}'` : 'Error moving items'
            notifier.notify(msg, 'warning')
            return
        }
        // let the user know
        if (status_code === 201) {
            notifier.notify(`Moved to a new <a href="#!/purchase-orders/manage/${result.artkey}">purchase order P${result.artkey}</a>`)
        } else {
            notifier.notify(`Moved to <a href="#!/purchase-orders/manage/${target_artkey}">P${target_artkey}</a>`) }

        // update collection
        selection.forEach((id) => collection.soft_delete(id))

        // reset form
        collection.state.selection.ids.splice(0)
        collection.state.selection.mode = ''
        collection.state.selection.all = false
        this.model.bulk_target_purchase_order_artkey = ''

        await this.fetch_target_purchase_order_options()
        this.model.backend_is_moving = false
    }

    view() {
        const selection = collection.state.selection
        const is_bulk_mode = this.model.move_mode === MoveMode.BULK
        const bulk_button_disabled = !is_bulk_mode || selection.ids.length === 0 && !selection.all

        return <div className="c-move-purchase-order-items">
            <div className="btn-toolbar">
                <div className="c-button-group">
                    <Button
                        active={false}
                        className="btn-back"
                        icon="back"
                        onclick={() => m.route.set(`/purchase-orders/manage/${this.model.source_purchase_order.artkey}`)}
                        variant="toggle"
                    />
                </div>
                <div className="c-button-group">
                    <Button
                        type="info"
                        variant="action"
                        text={is_bulk_mode ? 'End bulk' : 'Start bulk'}
                        icon={this.model.move_mode === MoveMode.BULK ? 'cancel' : 'execute'}
                        tip={() => this.model.move_mode === MoveMode.BULK ? 'End bulk mode' : 'Start bulk mode'}
                        onclick={() => {
                            if (this.model.move_mode === MoveMode.BULK) {
                                this.end_bulk_mode()
                            } else {
                                this.start_bulk_mode()}
                        }
                        }
                    />
                </div>
            </div>
            <div className="view-container">
                {is_bulk_mode && (
                    <PanelBulk collection={collection}>
                        <div className='bulk-control'>
                            <FieldSelect
                                placeholder={'Target for bulk'}
                                model={[this.model, 'bulk_target_purchase_order_artkey']}
                                options={this.model.bulk_target_options}
                                disabled={this.model.move_mode !== MoveMode.BULK}
                            />
                            <Button
                                icon="execute"
                                disabled={bulk_button_disabled || !this.model.bulk_target_purchase_order_artkey}
                                tip={() => {
                                    const target_text = this.model.bulk_target_purchase_order_artkey === 'NEW' ? 'new order' : `P${this.model.bulk_target_purchase_order_artkey}`
                                    const quantity_text = selection.all ? 'everything' : `${selection.ids.length} items`
                                    return this.model.move_mode === MoveMode.BULK && this.model.bulk_target_purchase_order_artkey
                                        ? `Move ${quantity_text} to ${target_text}`
                                        : 'Select target and items'
                                }}
                                onclick={async() => await this.move_pois_to_target()}
                            />
                        </div>
                    </PanelBulk>
                )}
                <CollectionView mode="table">
                    <CollectionHeader collection={collection} columns={this.columns} />
                    <CollectionItems
                        collection={collection}
                        columns={this.columns}
                    />

                </CollectionView>
            </div>
        </div>
    }
}
