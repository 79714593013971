import m from 'mithril'
import {
    Amount,
    AmountUnit,
    Button,
    CellBottleAndCase,
    CellProduct,
    Icon,
    CellMargin,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Memo,
    PanelFilters,
    ProcessStepper,
    RowActionDelete,
} from '@bitstillery/common/components'
import {format_iso_to_date, format_iso_to_date_time, format_money, titleize} from '@bitstillery/common/lib/format'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api, notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {calculate_margin_percentage, calculate_total_margin_soi} from '@/lib/utils'
import {CaseLabels} from '@/components/labels'
import {
    GetSalesOrderItemsCollectionViewResponse as CollectionResponse,
    CreateEntityResponse,
    EntityAlt,
    GetSalesOrderItemsCollectionViewResponse,
    SalesOrderAdditionalValueType,
    SalesOrderItemType,
    SalesOrderStatus,
    SalesOrderTBOItemStatus,
} from '@/factserver_api/fact2server_api'
import {CombinedOrderStatus} from '@/factserver_api/fact2server_api'
import {context, EntityType, methods} from '@/sales/orders/view/lib/context'
import {steps} from '@/sales/orders/view/lib/stepper'
import {accountIcon} from '@/accounts'
import {Link} from '@/components/discover'
import {$s} from '@/app'
import {OrdersViewCollectionHero} from '@/sales/orders/view/manage/collection_hero'
import {ManageButtons} from '@/sales/orders/view/manage/components/buttons'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferHistory} from '@/components/market_info/offer_history'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {AttachmentHelper} from '@/components/attachment_list'

export const SOI_STATUS = {
    [SalesOrderItemType.AdditionalItem]: {entity: 'SOA', icon: 'additional', type: 'surface'},
    [SalesOrderItemType.BasketItem]: {entity: 'SOBI', icon: 'cart', type: 'surface'},
    [SalesOrderItemType.CreditItem]: {entity: 'SOCI', icon: 'finance', type: 'warning'},
    [SalesOrderItemType.HistoricalItem]: {entity: 'SOHI', icon: 'delete_empty_outline', type: 'disabled'},
    [SalesOrderItemType.SalesOrderItem]: {entity: 'SOI', icon: 'sales', type: 'success'},
    [SalesOrderItemType.TBO]: {entity: 'SOTI', icon: 'clock', type: 'surface'},
    [SalesOrderItemType.Voucher]: {entity: 'VOUCHER', icon: 'voucher', type: 'success'},
}

export const collection: CollectionProxy = new CollectionProxy()

export const columns = [
    {
        name: 'Product',
        render: (row: CollectionResponse) => {
            return <CellProduct
                {...row}
                country_of_origin={row.item_country_of_origin || row.product_country_of_origin}
                customs_status={row.case_customs_status}
                gift_box_type={row.case_gift_box_type}
                number_of_bottles={row.case_number_of_bottles}
                tax_label={row.case_tax_label}
                additional_details={
                    <CaseLabels
                        show_as_icons={true}
                        case={{
                            serialized_item_tags: row.case_serialized_item_tags,
                            gift_box_type: row.case_gift_box_type,
                            remark: row.item_remark,
                            best_before_date: row.case_best_before_date,
                            tax_label: row.case_tax_label,
                        }}
                    />
                }
            />
        },
        width: '2fr',
    },
    {
        name: 'References',
        render: (row: CollectionResponse) => {
            if (row.sales_order_item_type === SalesOrderItemType.CreditItem) {
                let lot_link: JSX.Element | null = null
                let bottle_gtin: JSX.Element | null = null
                if (row.credit_target_item_lot) {
                    lot_link = <Link
                        href={`/stock/manage?search=${row.credit_target_item_lot}&item_selection=Available&account=${row.account_slug}`}>
                        {row.credit_target_item_lot}{' '}
                        {row.account_slug && row.account_name &&
                            accountIcon({
                                slug: row.account_slug,
                                name: row.account_name,
                            })
                        }
                    </Link>
                }
                if (row.credit_target_item_bottle_gtin_code) {
                    bottle_gtin = <small className={'help-block'}>
                        GTIN: {row.credit_target_item_bottle_gtin_code}
                    </small>
                }

                return <span>
                    {lot_link}
                    {bottle_gtin}
                </span>
            }
            const tbo_status: JSX.Element[] = []
            if (row.sales_order_item_type === SalesOrderItemType.TBO) {
                tbo_status.push(<span>TBO Status: {row.tbo_item_status}</span>)
            }

            const lot_links: JSX.Element[] = []
            if (row.item_lot && row.account_slug && row.account_name) {
                const account_slugs: string[] = row.account_slug.split(';')
                const account_names: string[] = row.account_name.split(';')
                row.item_lot.split(';').forEach((
                    item_lot: string, index: number,
                ) => (
                    lot_links.push(<Link
                        className="c-link-group"
                        href={`/stock/manage?search=${item_lot}&item_selection=Available&account=${account_slugs[index]}`}>
                        {accountIcon({
                            slug: account_slugs[index],
                            name: account_names[index],
                        })}
                        <Icon name="stock" size="s" type="unset"/>
                        {item_lot}{' '}

                    </Link>)
                ))
            }
            if (row.item_lot === '' && row.sales_order_item_type === SalesOrderItemType.SalesOrderItem) {
                lot_links.push(<span>{format_iso_to_date(row.purchase_order_eta)} expected</span>)
            }
            const po_links: JSX.Element[] = []
            if (
                row.purchase_order_reference
                && row.purchase_order_account_slug
                && row.purchase_order_account_name
            ) {
                const purchase_order_account_slugs: string[] = row.purchase_order_account_slug.split(';')
                const purchase_order_account_names: string[] = row.purchase_order_account_name.split(';')

                row.purchase_order_reference.split(';').forEach((
                    purchase_order_reference: string, index: number,
                ) => {
                    const purchase_order_artkey = +purchase_order_reference.substring(1)
                    po_links.push(
                        <Link
                            className="c-link-group"
                            href={`/purchase-orders/manage/${purchase_order_artkey}?account=${purchase_order_account_slugs[index]}`}
                        >
                            {accountIcon({
                                slug: purchase_order_account_slugs[index],
                                name: purchase_order_account_names[index],
                            })}
                            <Icon name="cart" size="s" type="unset"/>
                            {purchase_order_reference}{' '}

                        </Link>,
                    )
                })
            }

            return <div className="td-group">
                <div className="header">
                    {(() => {
                        if (row.original_sales_order_reference) {
                            return <Link
                                href={`/sales/orders/${row.original_sales_order_artkey}/view/manage`}
                            >
                                {row.account_slug && row.account_name && (
                                    accountIcon({slug: row.account_slug, name: row.account_name})
                                )}
                                {row.original_sales_order_reference}{' '}
                            </Link>
                        }
                    })()}
                </div>
                {!!tbo_status.length && <span className="details">{tbo_status}</span>}
                {!!po_links.length && <span className="details">{po_links}</span>}
                {!!lot_links.length && <span className="details">{lot_links}</span>}
            </div>

        },
    },
    {
        name: 'Purchase/cs',
        render: (row) => {
            if (!(row.item_euro_was_bought_for && +row.item_euro_was_bought_for > 0)) return null

            return <span className="td-group">
                <div className="header">
                    <Amount
                        amount={row.item_euro_was_bought_for}
                        currency={$s.currencies.default}
                    />
                </div>

                <span className="details">
                    Costs:&nbsp;
                    <Amount
                        amount={row.item_euro_was_bought_for_plus_costs}
                        currency={$s.currencies.default}
                    />
                </span>
            </span>
        },
    },
    {
        name: 'Quantity',
        render: (row) => {
            if ([SalesOrderItemType.AdditionalItem, SalesOrderItemType.Voucher].includes(row.sales_order_item_type)) {
                return row.number_of_cases
            } else {
                const bottle_quantity = row.number_of_cases * row.case_number_of_bottles
                return <CellBottleAndCase
                    bottle_text={bottle_quantity ? `${bottle_quantity.toString()} btl` : ''}
                    case_text={row.number_of_cases ? `${row.number_of_cases.toString()} cs` : ''}
                />
            }
        },
    },
    {
        name: 'Price',
        render: (row: CollectionResponse) => {
            if (row.sales_order_item_type === SalesOrderItemType.AdditionalItem) {

                if (row.additional_value_type === SalesOrderAdditionalValueType.FIXED) {
                    return row.price_per_case
                } else {
                    return `${row.price_per_case}%`
                }
            } else if (row.sales_order_item_type === SalesOrderItemType.Voucher) {
                return row.value_per_quantity
            }
            const rate = row.sales_order_item_type === SalesOrderItemType.CreditItem ?
                row.original_sales_order_sold_against_rate : context.data.sales_order.sold_against_rate
            const currency = row.sales_order_item_type === SalesOrderItemType.CreditItem ?
                row.original_sales_order_was_sold_in : context.data.sales_order.was_sold_in
            return <span>
                <AmountUnit
                    case_amount={row.price_per_case_excl_excise}
                    case_amount_excise={+row.price_per_case_excl_excise + +(row.excise_per_case ?? 0)}
                    case_number_of_bottles={row.case_number_of_bottles}
                    display_currency={currency}
                    should_include_excise={context.data.sales_order.includes_excise}
                    currency={currency}
                    rate={rate}
                />
                {row.was_first_sold_for && (row.was_first_sold_for !== row.price_per_case) && (
                    <span className={'help-block'}>
                            First sold for:{' '}
                        <Amount
                            amount={row.was_first_sold_for}
                            currency={currency}
                            rate={rate}
                        />
                    </span>
                )}
            </span>
        },
    },
    {
        name: 'Total value',
        render: (row: CollectionResponse) => {
            if (row.sales_order_item_type === SalesOrderItemType.AdditionalItem) {
                const price_per_case = Number(row.price_per_case)

                if (row.additional_value_type === SalesOrderAdditionalValueType.FIXED) {
                    return row.number_of_cases * price_per_case
                } else {
                    const percentage_amount = context.data.sales_order.total_before_discounts * (price_per_case / 100)
                    return format_money(percentage_amount, context.data.sales_order.was_sold_in)
                }
            }
            return <div className='td-group'>
                <div className='header'>
                    <Amount
                        amount={+row.price_per_case * row.number_of_cases}
                        currency={context.data.sales_order.was_sold_in}
                        rate={context.data.sales_order.sold_against_rate}
                    />
                </div>

                {context.data.sales_order.includes_excise && (
                    <span className="details">
                    Ex Duty:{' '}
                        <Amount
                            amount={+row.price_per_case_excl_excise * row.number_of_cases}
                            currency={context.data.sales_order.was_sold_in}
                            rate={context.data.sales_order.sold_against_rate}
                        />
                    </span>
                )}
            </div>
        },
    },
    {
        name: 'Margin',
        render: (row: CollectionResponse) => {
            if (!row.item_euro_was_bought_for || +row.item_euro_was_bought_for === 0) {
                return null
            }

            const margin = calculate_total_margin_soi({
                price_per_case: +row.price_per_case,
                excise_per_case: row.excise_per_case && context.data.sales_order.includes_excise ? +row.excise_per_case : 0,
                number_of_cases: row.number_of_cases,
                item: {euro_was_bought_for: +row.item_euro_was_bought_for},
            }, context.data.sales_order)
            const margin_percentage = calculate_margin_percentage({
                price_per_case: +row.price_per_case,
                excise_per_case: row.excise_per_case && context.data.sales_order.includes_excise ? +row.excise_per_case : 0,
                number_of_cases: row.number_of_cases,
                item: {euro_was_bought_for: +row.item_euro_was_bought_for},
            }, context.data.sales_order)
            const credit_modifier = row.sales_order_item_type === SalesOrderItemType.CreditItem ? -1 : 1
            return <div className='td-group'>
                <div className='header'>
                    <CellMargin value={margin_percentage * credit_modifier}/>
                </div>
                <span className="details">
                    <Amount amount={margin * credit_modifier} currency={$s.currencies.default}/>
                </span>
            </div>
        },
    },

]

interface ManageCollectionAttrs {
    attachment_helper: AttachmentHelper
}

export class ManageCollection extends MithrilTsxComponent<ManageCollectionAttrs> {

    async oninit() {
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/sales-orders/${context.data.root_artkey}/items/collection-view`,
            },
            page_size: 100,
        })

        setTimeout(() => {
            $('.filter-search input').trigger('focus')
        }, 1000)
    }

    async delete_entry(row:GetSalesOrderItemsCollectionViewResponse) {
        const [entity_type, entity_artkey] = row.artkey.split('-')
        const collection_entity = collection.entities[entity_type]
        let {status_code} = await api.delete<CreateEntityResponse>(`${collection_entity.endpoint}/${entity_artkey}`)
        if (status_code > 299) {
            notifier.notify('Error while deleting the item', 'warning')
            return
        }
        collection.select_next()
        collection.soft_delete(row.artkey)

        await Promise.all([
            methods.fetch_sales_order_items_summary(),
            methods.fetch_active_vouchers(),
        ])
        notifier.notify('Item removed from sales order', 'info')
    }

    async convert_basket_to_soi(row: GetSalesOrderItemsCollectionViewResponse) {
        let {status_code} = await api.post<CreateEntityResponse>(
            `discover/sales-orders/${context.data.root_artkey}/portal-order-items/${row.artkey.split('-')[1]}/confirm`, {}, true)
        if (status_code > 299) {
            return
        }

        collection.reset_query()
        await methods.fetch_sales_order_with_items(context.data.root_artkey)
        notifier.notify('Basket item upgraded!', 'info')
    }

    async confirm_tbo_for_purchase(row: GetSalesOrderItemsCollectionViewResponse) {
        let {status_code} = await api.post<CreateEntityResponse>(
            `discover/sales-orders/${context.data.root_artkey}/tbo-items/${row.artkey.split('-')[1]}/approve`, {}, true)
        if (status_code > 299) {
            notifier.notify('Error while confirming the tbo', 'warning')
            return
        }

        collection.update_item(row.artkey)
        notifier.notify('TBO item confirmed for purchase', 'info')
    }

    view(vnode: m.Vnode<ManageCollectionAttrs>): m.Children {
        const eu_address_alert = context.data.sales_order.supplier_requires_eu_address && context.data.sales_order.has_items_missing_eu_address
        const eu_alert_message = 'This relation is operating from a country that requires an EU address on the label. This sales order contains items that are missing an EU address on the label.'

        return [
            <PanelFilters className="context-default" collection={collection}/>,
            <CollectionView className="context-default" mode="table">
                <ManageButtons attachment_helper={vnode.attrs.attachment_helper} collection={collection}/>
                <ProcessStepper
                    className="mb-3"
                    data={context.data.stepper}
                    process={context.data.sales_order.from_portal ? [
                        steps[CombinedOrderStatus.Cancelled],
                        steps[CombinedOrderStatus.Pending],
                        steps[CombinedOrderStatus.Finalized],
                        steps[CombinedOrderStatus.Confirmed],
                        steps[CombinedOrderStatus.Invoiced],
                    ] : [
                        steps[CombinedOrderStatus.Cancelled],
                        steps[CombinedOrderStatus.Saved],
                        steps[CombinedOrderStatus.Confirmed],
                        steps[CombinedOrderStatus.Invoiced],
                    ]}
                    variant="horizontal"
                />
                <OrdersViewCollectionHero className="mb-3" sales_order={context.data.sales_order}/>
                <Memo
                    alert={eu_address_alert ? eu_alert_message : undefined}
                    className="mb-3"
                    model={context.data.sales_order.supplier_memo}
                />

                {context.data.sales_order.is_part_of_buy_from_account && <div className={'alert alert-info'}>
                    <span className={'glyphicon glyphicon-info-sign'} />
                    <span>This sales order is part of a buy-from-account action from another reseller.
                        {' '}
                        <Link
                            href={`/sales/orders/${context.data.sales_order.is_part_of_buy_from_account_sales_order_artkey}/view/manage?account=${context.data.sales_order.is_part_of_buy_from_account_sales_order_account_slug}`}
                        >
                            {context.data.sales_order.is_part_of_buy_from_account_sales_order_reference}{' '}
                            {accountIcon({
                                slug: context.data.sales_order.is_part_of_buy_from_account_sales_order_account_slug || '',
                                name: context.data.sales_order.is_part_of_buy_from_account_sales_order_account_slug || '',
                            })}
                        </Link>
                    </span>
                </div>}

                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        const actions: m.Child[] = []
                        let [entity_type] = row.artkey.split('-') as any
                        const collection_entity = collection.entities[entity_type] ? collection.entities[entity_type] : null

                        if (collection_entity && collection_entity.supported_methods.includes('PUT')) {
                            actions.push(
                                <Button
                                    active={context.link_entity_active(EntityType.SOI, row.artkey)}
                                    disabled={context.data.sales_order.pre_advice_reference}
                                    icon="edit"
                                    link={context.link_entity(EntityAlt.SOI, row.artkey)}
                                    link_mode="replace"
                                    tip={(() => {
                                        if (context.data.sales_order.pre_advice_reference) {
                                            return 'There is already a pre-advice; items cannot be changed anymore.'
                                        }
                                        return 'Edit this sales order item'
                                    })()}
                                    type="info"
                                    variant="toggle"
                                />,
                            )
                        }

                        if (row.sales_order_item_type === SalesOrderItemType.BasketItem) {
                            actions.push(<Button
                                icon="cartUp"
                                tip={'Convert this to a sales order item'}
                                onclick={async() => await this.convert_basket_to_soi(row)}
                                type="info"
                                variant="toggle"
                            />)
                        }
                        if (
                            row.sales_order_item_type === SalesOrderItemType.TBO &&
                            row.tbo_item_status === SalesOrderTBOItemStatus.NotYetApproved
                        ) {
                            actions.push(<Button
                                icon="thumbUp"
                                tip={'Confirm this TBO for purchase'}
                                onclick={async() => await this.confirm_tbo_for_purchase(row)}
                                type="info"
                                variant="toggle"
                            />)
                        }
                        if (
                            (collection_entity && collection_entity.supported_methods.includes('DELETE')) &&
                            context.data.sales_order.sales_order_status !== SalesOrderStatus.Invoiced &&
                            row.sales_order_item_type !== SalesOrderItemType.HistoricalItem
                        ) {
                            actions.push(
                                <RowActionDelete
                                    needs_confirmation={true}
                                    row={row}
                                    row_delete={async() => await this.delete_entry(row)}
                                    tip={'Remove this entry from the sales order'}
                                    variant="activate"
                                />,
                            )
                        }

                        return actions
                    }}
                    row_detail={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        if ([SalesOrderItemType.AdditionalItem, SalesOrderItemType.Voucher].includes(row.sales_order_item_type)) {
                            return null
                        }
                        return [
                            <CaseInfoPanel
                                bottle_artkey={row.bottle_artkey}
                                ignore_ref={true}
                            />,
                            <div className="columns">
                                <div className="column is-12">
                                    <OfferHistory
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                    />
                                </div>
                            </div>,
                            <div className="columns">
                                <div className="column is-12">
                                    <BottleSalesOrders
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                        current_client_artkey={context.data.sales_order.supplier_artkey}
                                    />
                                </div>
                            </div>,
                        ]
                    }}
                    row_separator={(row: GetSalesOrderItemsCollectionViewResponse, index: number, items: GetSalesOrderItemsCollectionViewResponse[]) => {
                        if (collection.state.sort.by === 'was_last_updated_on_ungrouped') {
                            return null
                        }
                        if (index === 0) return {
                            icon: SOI_STATUS[row.sales_order_item_type].icon,
                            type: SOI_STATUS[row.sales_order_item_type].type,
                            text: row.sales_order_item_type,
                        }

                        const prev_item = items[index - 1]
                        if (row.sales_order_item_type !== prev_item.sales_order_item_type) {
                            return {
                                icon: SOI_STATUS[row.sales_order_item_type].icon,
                                type: SOI_STATUS[row.sales_order_item_type].type,
                                text: row.sales_order_item_type,
                            }
                        }

                        return null
                    }}
                    row_status={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        let logistics_status = {
                            icon: '',
                            tip: '',
                            type: 'info',
                        }
                        if (row.sales_order_item_type === SalesOrderItemType.SalesOrderItem) {
                            if (context.data.sales_order.warehouse_status && row.loendersloot_outtake_item_lot) {
                                logistics_status.icon = 'fork_lift'
                                logistics_status.tip = `
                                    Loendersloot outtake:
                                    <ul>
                                        <li>${row.loendersloot_outtake_item_description}</li>
                                        <li>Loendersloot lot: ${row.loendersloot_outtake_item_lot}</li>
                                        <li>Loendersloot article: ${row.loendersloot_outtake_item_article_lo}</li>
                                        <li>Loendersloot quantity: ${row.loendersloot_outtake_item_quantity}</li>
                                    </ul>
                                `
                            }
                            if (context.data.sales_order.warehouse_status && !row.loendersloot_outtake_item_lot) {
                                logistics_status.icon = 'fork_lift'
                                logistics_status.tip = 'Sales order item is not matched with Loendersloot outtake. Contact Loendersloot and verify the outtake with them.'
                                logistics_status.type = 'warning'
                            }
                        }

                        if (row.sales_order_item_type === SalesOrderItemType.TBO) {

                            const purchase_order_date = row.purchase_order_date ? format_iso_to_date(row.purchase_order_date) : ''
                            if (purchase_order_date) {
                                logistics_status.icon = 'truck'
                                logistics_status.tip = `${row.tbo_item_status} ${purchase_order_date}`
                                logistics_status.tip += row.purchase_order_eta ? `ETA: ${format_iso_to_date(row.purchase_order_eta)}` : ''
                            }
                        }

                        return {
                            render: [
                                <Icon
                                    name={SOI_STATUS[row.sales_order_item_type].icon}
                                    tip={(() => {
                                        if (row.sales_order_item_type === SalesOrderItemType.TBO && row.have_case_in_stock) {
                                            return 'A different item for the same specs and case details is already in stock'
                                        }
                                        if (row.sales_order_item_type === SalesOrderItemType.CreditItem) {
                                            return null
                                        }

                                        return <span className={'c-item-source'}>
                                            <div>History {row.sales_order_item_type}</div>
                                            <ul>
                                                <li>Created on: {format_iso_to_date_time(row.created_on)}</li>
                                                <li>Created in: {row.created_from_portal_timestamp ? 'Portal' : 'Discover'}</li>
                                                <li>Last updated on: {format_iso_to_date_time(row.was_last_updated_on)}</li>
                                                <li>Last updated by: {titleize(row.last_updated_by_user_name || '-')}</li>
                                                <li>Added from portal: {format_iso_to_date_time(row.created_from_portal_timestamp || '')}</li>
                                                <li>Last updated in portal by: {row.last_updated_by_portal_user_name || '-'}</li>
                                            </ul>
                                        </span>
                                    })}
                                    type={(() => {
                                        if (row.sales_order_item_type === SalesOrderItemType.TBO && row.have_case_in_stock) {
                                            return 'warning'
                                        }

                                        if (row.item_lot === '' && row.sales_order_item_type === SalesOrderItemType.SalesOrderItem && row.purchase_order_eta) {
                                            return 'warning'
                                        }

                                        return SOI_STATUS[row.sales_order_item_type].type
                                    })()}
                                />,
                                logistics_status.icon ? <Icon
                                    name={logistics_status.icon}
                                    tip={logistics_status.tip}
                                    type={logistics_status.type}
                                /> : null,
                            ],
                            type: (() => {
                                if (row.item_lot === '' && row.sales_order_item_type === SalesOrderItemType.SalesOrderItem && row.purchase_order_eta) {
                                    return 'warning'
                                }
                                return SOI_STATUS[row.sales_order_item_type].type
                            })(),
                        }
                    }}
                />
            </CollectionView>,
        ]
    }
}
