import {
    Button,
    ButtonGroup,
    Dialog,
    FieldCheckbox,
    FieldSelect,
    FieldSwitch,
    FieldText,
    Spinner,
} from '@bitstillery/common/components'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api, notifier} from '@bitstillery/common/app'
import {invalid_fields, invalid_fields_format, required, validation} from '@bitstillery/common/lib/validation'
import {classes} from '@bitstillery/common/lib/utils'
import {european_union_countries} from '@bitstillery/common/lib/countries'

import {context} from '../lib/context'

import {GetIncotermsResponse, GetInsuranceTypeResponse, GetRelationLocationResponse} from '@/factserver_api/fact2server_api'
import {check_vat_id} from '@/data/vatcheck/actions'

interface DialogSettingsAttrs {
    cancel: () => Promise<unknown>
    ok: () => Promise<unknown>
}

export class DialogSettings extends MithrilTsxComponent<DialogSettingsAttrs> {
    data = proxy({
        insurance_type_options: [] as GetInsuranceTypeResponse[],
        incoterm_options: [] as GetIncotermsResponse[],
        relation_locations: [] as GetRelationLocationResponse[],
        origin_locations: [] as GetRelationLocationResponse[],
        currenty_selected: 0,
        includes_excise_text: '',
        vat_country: '',
        requires_vat_check: false,
        is_checking_vat: false,
        vat_id_message: '',
    })

    $f = proxy({
        vat_id: '' as string | null,
        vat_id_confirmed: false,
        includes_excise: false,
        includes_excise_confirmed: false,
        insurance_type_artkey: null! as number,
        insurance_type_confirmed: false,
        incoterm: null! as string,
        incoterm_confirmed: false,
        incoterm_location: null! as string,
        incoterm_location_confirmed: false,
        relation_sales_ledger_confirmed: false,
        origin_confirmed: false,
        origin_artkey: null! as number,
        destination_confirmed: false,
        destination_artkey: null! as number,
    })

    $v = {
        includes_excise_confirmed: validation([this.$f, 'includes_excise_confirmed'], required()),
        insurance_type_confirmed: validation([this.$f, 'insurance_type_confirmed'], required()),
        insurance_type_artkey: validation([this.$f, 'insurance_type_artkey'], required()),
        incoterm_confirmed: validation([this.$f, 'incoterm_confirmed'], required()),
        incoterm: validation([this.$f, 'incoterm'], required()),
        incoterm_location_confirmed: validation([this.$f, 'incoterm_location_confirmed'], required()),
        incoterm_location: validation([this.$f, 'incoterm'], required()),
        relation_sales_ledger_confirmed: validation([this.$f, 'relation_sales_ledger_confirmed'], required()),
        origin_confirmed: validation([this.$f, 'origin_confirmed'], required()),
        origin_artkey: validation([this.$f, 'origin_artkey'], required()),
        destination_confirmed: validation([this.$f, 'destination_confirmed'], required()),
        destination_artkey: validation([this.$f, 'destination_artkey'], required()),
    }

    watchers: type_remove_watch_function[] = []

    async oninit() {
        this.watchers.push(watch(
            this.$f,
            'includes_excise',
            () => this.data.includes_excise_text = this.$f.includes_excise ? 'Includes excise' : 'No excise',
        ))
        await Promise.all([
            this.fetch_incoterm(),
            this.fetch_insurance_types(),
            this.fetch_origin_warehouses(),
            this.fetch_relation_destinations(context.data.sales_order.supplier_artkey),
        ])

        this.data.vat_country = context.data.sales_order.vat_country_code || ''
        this.data.requires_vat_check = european_union_countries.includes(this.data.vat_country)

        this.$f.vat_id = context.data.sales_order.vat_id
        this.$f.includes_excise = context.data.sales_order.includes_excise
        this.$f.insurance_type_artkey = context.data.sales_order.insurance_type_artkey
        this.$f.incoterm = context.data.sales_order.incoterm
        this.$f.incoterm_location = context.data.sales_order.incoterm_location
        this.$f.origin_artkey = context.data.sales_order.origin_artkey || -1
        this.$f.destination_artkey = context.data.sales_order.destination_artkey || -1

        // Either check the vat-id (for eu customers), or set to the next step.
        if (this.data.requires_vat_check) {
            check_vat_id(
                undefined,
                (is_loading) => this.data.is_checking_vat = is_loading,
                (valid) => {
                    if (valid) {
                        this.data.currenty_selected = 1
                        this.$f.vat_id_confirmed = true
                    }
                    else {
                        notifier.notify('Cannot validate the vat-id. Please edit the relation', 'warning')
                    }
                },
                this.data.vat_country,
                this.$f.vat_id || '',
                true,
            )
        } else {
            this.data.vat_id_message = 'Relation is a non-eu customer'
            this.data.currenty_selected = 1
            this.$f.vat_id_confirmed = true
        }
    }

    onremove() {
        this.watchers.forEach((watcher) => watcher())
    }

    async fetch_insurance_types() {
        const {result} = await api.get<GetInsuranceTypeResponse[]>('discover/data/insurance-types')
        this.data.insurance_type_options.splice(0, this.data.insurance_type_options.length, ...result)
    }

    async fetch_incoterm() {
        const {result} = await api.get<GetIncotermsResponse[]>('discover/data/incoterms')
        this.data.incoterm_options = result
    }

    async fetch_relation_destinations(relation_artkey?: number) {
        if (!relation_artkey) {
            return
        }
        const {result} = await api.get<GetRelationLocationResponse[]>(`discover/relations/${relation_artkey}/locations`)
        this.data.relation_locations.splice(0, this.data.relation_locations.length)
        this.data.relation_locations.push(...result)
    }

    async fetch_origin_warehouses() {
        this.data.origin_locations.splice(0, this.data.origin_locations.length)
        const {result} = await api.get<GetRelationLocationResponse[]>('discover/stock/locations')
        this.data.origin_locations.push(...result)
    }

    async confirm_settings(vnode: m.Vnode<DialogSettingsAttrs>) {
        const {status_code} = await api.post(
            `discover/sales-orders/${context.data.sales_order.artkey}/confirm-settings`, this.$f, true,
        )
        if (status_code > 299) {
            return
        }

        await vnode.attrs.ok()
    }

    view(vnode: m.Vnode<DialogSettingsAttrs>): m.Children {
        const selected = this.data.currenty_selected
        return <Dialog
            className="c-dialog-settings"
            onclose={() => {
                context.data.dialog.confirm_settings = null
            }}
            title={`Confirm settings for ${context.data.root_artkey}`}
        >
            {this.data.vat_id_message && <div className={classes('confirmation-row', {
                active: selected === 0,
                completed: true,
                disabled: selected !== 0 && !this.$f.vat_id_confirmed,
            })}>
                <div className='label'>
                    <FieldCheckbox
                        model={[this.$f, 'vat_id_confirmed']}
                        disabled={true}
                        type={'success'}
                    />
                    {this.data.vat_id_message}
                </div>
            </div>}
            {!this.data.vat_id_message && <div className={classes('confirmation-row', {
                active: selected === 0,
                completed: this.$f.vat_id_confirmed,
                disabled: selected !== 0 && !this.$f.vat_id_confirmed,
            })}>
                <div className='label'>
                    <FieldCheckbox
                        model={[this.$f, 'vat_id_confirmed']}
                        disabled={selected !== 0 || this.data.is_checking_vat}
                        type={this.$f.vat_id_confirmed ? 'success' : 'default'}
                    />
                    {!this.$f.vat_id_confirmed ? <span>VAT Checking...<Spinner /></span> : <span>VAT: {this.$f.vat_id} (valid)</span>}
                </div>
            </div>}

            <div className={classes('confirmation-row', {
                active: selected === 1,
                completed: this.$f.includes_excise_confirmed,
                disabled: selected !== 1 && !this.$f.includes_excise_confirmed,
            })}>
                <div className='label'>
                    <FieldCheckbox
                        model={[this.$f, 'includes_excise_confirmed']}
                        disabled={selected !== 1}
                        validation={this.$v.includes_excise_confirmed}
                        onclick={() => this.data.currenty_selected ++}
                        type={this.$f.includes_excise_confirmed ? 'success' : 'default'}
                    />
                    {!this.$f.includes_excise_confirmed ? <span>Verify Excise</span> : <span>Excise: {this.$f.includes_excise ? 'With excise' : 'No excise'}</span>}
                </div>
                <div className='value'>
                    <FieldSwitch
                        label="Has excise"
                        model={[this.$f, 'includes_excise']}
                        type="success"
                    />
                </div>
            </div>

            <div className={classes('confirmation-row', {
                active: selected === 2,
                completed: this.$f.insurance_type_confirmed,
                disabled: selected !== 2 && !this.$f.insurance_type_confirmed,
            })}>
                <div className='label'>
                    <FieldCheckbox
                        model={[this.$f, 'insurance_type_confirmed']}
                        disabled={selected !== 2}
                        validation={this.$v.insurance_type_confirmed}
                        onclick={() => this.data.currenty_selected ++}
                        type={this.$f.insurance_type_confirmed ? 'success' : 'default'}
                    />
                    {!this.$f.insurance_type_confirmed ? <span>Verify Insurance</span> : <span>Insurance: {this.data.insurance_type_options.find((i) => i.artkey === this.$f.insurance_type_artkey)?.name}</span>}
                </div>
                <div className="value">
                    <FieldSelect
                        model={[this.$f, 'insurance_type_artkey']}
                        disabled={selected !== 2}
                        options={this.data.insurance_type_options.map((i) => ({value: i.artkey, label: i.name}))}
                        type={this.$f.insurance_type_confirmed ? 'success' : 'default'}
                        validation={this.$v.insurance_type_artkey}
                    />
                </div>
            </div>

            <div className={classes('confirmation-row', {
                active: selected === 3,
                completed: this.$f.origin_confirmed,
                disabled: selected !== 3 && !this.$f.origin_confirmed,
            })}>
                <div className="label">
                    <FieldCheckbox
                        disabled={selected !== 3}
                        model={[this.$f, 'origin_confirmed']}
                        validation={this.$v.origin_confirmed}
                        onclick={() => this.data.currenty_selected++}
                        type={this.$f.origin_confirmed ? 'success' : 'default'}
                    />
                    {!this.$f.origin_confirmed ? <span>Verify Origin</span> : <span>Origin: {this.data.origin_locations.find((i) => i.artkey === this.$f.origin_artkey)?.name}</span>}
                </div>
                <div className="value">
                    <FieldSelect
                        model={[this.$f, 'origin_artkey']}
                        disabled={selected !== 3}
                        options={this.data.origin_locations.map((i) => ({
                            value: i.artkey,
                            label: `${i.name} - ${i.city} (${i.country_code})`,
                        }))}
                        validation={this.$v.origin_artkey}
                    />
                </div>
            </div>
            <div className={classes('confirmation-row', {
                active: selected === 4,
                completed: this.$f.destination_confirmed,
                disabled: selected !== 4 && !this.$f.destination_confirmed,
            })}>
                <div className="label">
                    <FieldCheckbox
                        disabled={selected !== 4}
                        model={[this.$f, 'destination_confirmed']}
                        validation={this.$v.destination_confirmed}
                        onclick={() => this.data.currenty_selected++}
                        type={this.$f.destination_confirmed ? 'success' : 'default'}
                    />
                    {!this.$f.destination_confirmed ? <span>Verify Destination</span> : <span>Destination: {this.data.relation_locations.find((i) => i.artkey === this.$f.destination_artkey)?.name}</span>}
                </div>
                <div className="value">
                    <FieldSelect
                        model={[this.$f, 'destination_artkey']}
                        disabled={selected !== 4}
                        options={this.data.relation_locations.map((i) => ({
                            value: i.artkey,
                            label: `${i.name} - ${i.city} (${i.country_code})`,
                        }))}
                        validation={this.$v.destination_artkey}
                    />
                </div>
            </div>

            <div className={classes('confirmation-row', {
                active: selected === 5,
                completed: this.$f.incoterm_confirmed,
                disabled: selected !== 5 && !this.$f.incoterm_confirmed,
            })}>
                <div className="label">
                    <FieldCheckbox
                        model={[this.$f, 'incoterm_confirmed']}
                        disabled={selected !== 5}
                        onclick={() => this.data.currenty_selected++}
                        type={this.$f.incoterm_confirmed ? 'success' : 'default'}
                        validation={this.$v.incoterm_confirmed}
                    />
                    {!this.$f.incoterm_confirmed ? <span>Verify Incoterm</span> : <span>Incoterm: {this.data.incoterm_options.find((i) => i.code === this.$f.incoterm)?.description}</span>}
                </div>
                <div className="value">
                    <FieldSelect
                        model={[this.$f, 'incoterm']}
                        disabled={selected !== 5}
                        options={this.data.incoterm_options.map((i) => ({value: i.code, label: `${i.code} (${i.description})`}))}
                        validation={this.$v.incoterm}
                    />
                </div>
            </div>

            <div className={classes('confirmation-row', {
                active: selected === 6,
                completed: this.$f.incoterm_location_confirmed,
                disabled: selected !== 6 && !this.$f.incoterm_location_confirmed,
            })}>
                <div className="label">
                    <FieldCheckbox
                        model={[this.$f, 'incoterm_location_confirmed']}
                        disabled={selected !== 6}
                        validation={this.$v.incoterm_location_confirmed}
                        type={this.$f.incoterm_location_confirmed ? 'success' : 'default'}
                        onclick={() => this.data.currenty_selected++}
                    />
                    {!this.$f.incoterm_location_confirmed ? <span>Verify Incoterm Location</span> : <span>Incoterm Location: {this.$f.incoterm_location}</span>}
                </div>
                <div className="value">
                    <FieldText
                        model={[this.$f, 'incoterm_location']}
                        disabled={selected !== 6}
                        validation={this.$v.incoterm_location}
                    />
                </div>
            </div>

            <div className={classes('confirmation-row', {
                active: selected === 7,
                completed: this.$f.relation_sales_ledger_confirmed,
                disabled: selected !== 7 && !this.$f.relation_sales_ledger_confirmed,
            })}>
                <div className="label">
                    <FieldCheckbox
                        model={[this.$f, 'relation_sales_ledger_confirmed']}
                        disabled={selected !== 7}
                        onclick={() => this.data.currenty_selected++}
                        type={this.$f.relation_sales_ledger_confirmed ? 'success' : 'default'}
                        validation={this.$v.relation_sales_ledger_confirmed}
                    />
                    {!this.$f.relation_sales_ledger_confirmed ? <span>Verify Sales Ledger</span> : <span>Sales Ledger: {context.data.sales_order.relation_sales_ledger_description}</span>}
                </div>
                <div className="value">
                    <FieldText
                        disabled={true}
                        model={[context.data.sales_order, 'relation_sales_ledger_description']}
                    />
                </div>
            </div>

            <ButtonGroup>
                <Button
                    icon="stop"
                    onclick={async() => {
                        await vnode.attrs.cancel()
                    }}
                    text="Cancel"
                />
                <Button
                    disabled={invalid_fields(this.$v).length > 0}
                    icon="save"
                    onclick={async() => await this.confirm_settings(vnode)}
                    text={'Confirm settings'}
                    tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                    type="success"
                />
            </ButtonGroup>
        </Dialog>
    }
}
