import m from 'mithril'
import {
    Button,
    FieldMoney,
    FieldProduct,
    FieldText,
    Memo,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    invalid_fields,
    invalid_fields_format,
} from '@bitstillery/common/lib/validation'
import {focus_field} from '@bitstillery/common/lib/utils'

import {collection, select_row} from '@/sales/orders/view/manage/components/collection_sellable'
import {context, EntitySOTI, EntityType, methods} from '@/sales/orders/view/lib/context'
import {OrderSummary} from '@/sales/orders/view/manage/components/order_summary'

export class ManageTboItem extends MithrilTsxComponent<unknown> {

    view(_vnode: m.Vnode<unknown, this>) {
        const entity = context.data.entities.SOTI as EntitySOTI
        const $v = context.$v.SOTI

        const invalid = invalid_fields($v)
        const sales_order_currency = context.data.sales_order.was_sold_in
        const spli_currency = entity.spli_currency
        const currency_alert = spli_currency && sales_order_currency !== spli_currency
            ? `The supplier price list is in another currency (${spli_currency}) than this sales order (${sales_order_currency})`
            : undefined

        return <div className="c-manage-tbo-item sales orders">
            <FieldProduct
                collection={collection}
                context={context}
                entity={entity}
                entity_type={EntityType.SOTI}
                filter={collection.filters.search}
                linked={entity.artkey || entity.source_artkey} // Sellable or entity selected
                locked={entity.artkey} // Lock existing entities
                select_row={select_row}
                tabindex={19}
                debounce_on_search={400}
            />

            <Memo alert={currency_alert} className="mb-3" />

            <FieldText
                disabled={context.data.entity_type !== 'SOTI' && !context.data.entities.SOTI.artkey}
                help="Number of cases to add to the order."
                label="Cases"
                max={entity.number_of_cases_available}
                min={1}
                model={[entity, 'number_of_cases']}
                placeholder={entity.number_of_cases_available ? `Maximum: ${entity.number_of_cases_available}` : 'Number of cases...'}
                type="number"
                tabindex={20}
                validation={$v.number_of_cases}
            />

            <FieldMoney
                currency={[context.data.sales_order, 'was_sold_in']}
                disabled={context.data.entity_type !== 'SOTI' && !context.data.entities.SOTI.artkey}
                help="The price per case to sell this item for in this order."
                label={context.data.sales_order.includes_excise ? 'Price per case (incl. excise)' : 'Price per case'}
                model={[entity, 'price_per_case']}
                placeholder="Price per case"
                tabindex={21}
                validation={$v.price_per_case}
            />

            <Button
                className="btn-submit"
                disabled={invalid.length}
                icon="save"
                onclick={async() => {
                    await methods.upsert_entity(EntityType.SOTI, {
                        offer_item_artkey: entity.offer_item_artkey,
                        number_of_cases: entity.number_of_cases,
                        price_per_case: entity.price_per_case,
                        purchase_order_item_artkey: entity.purchase_order_item_artkey,
                        supplier_price_list_item_artkey: entity.supplier_price_list_item_artkey,
                    })
                    focus_field(19)
                }}
                text={context.data.entity_artkey ? `Update ${entity.title}` : `Add ${entity.title}`}
                tabindex={22}
                tip={() => invalid_fields_format(invalid_fields($v), 'tip')}
                type="success"
            />

            {!entity.artkey && <OrderSummary />}
        </div>
    }
}
