import m from 'mithril'
import {
    Button,
    ButtonDataCard,
    ButtonGroup,
} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'
import {AccountSlug} from '@bitstillery/common/account/account.ts'
import {
    proforma_or_invoice_renderer,
    ProformaInvoiceRendererAttrs,
    ProformaInvoice,
} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {DateTime} from 'luxon'
import {SalesOrder} from '@bitstillery/common/models/sales_order'
import {Account} from '@bitstillery/common/models/account'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {mount_dialog} from '@bitstillery/common/lib/dialog'
import {proxy} from '@bitstillery/common/lib/proxy'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {LoenderslootFlow, SendToLoenderslootModal} from '../../../components/send_to_loendersloot_modal'

import {CombinedOrderStatus} from '@/factserver_api/fact2server_api'
import {context, EntityType, methods} from '@/sales/orders/view/lib/context'
import {$m} from '@/app'
import {auto_attach_buy_from_account_supplier_invoices} from '@/purchase_orders/auto_attach_bfa_attachments'
import {download_binary_file_from_base64_str, formatDate} from '@/_utils'
import {GetFastSalesOrderWithItemsResponse, is_exw_loendersloot, is_origin_loendersloot} from '@/factserver_api/sales_api'
import {DeliveryStatus} from '@/factserver_api/fact2server_api'
import {PDFHelper} from '@/components/pdf_helper'
import {AttachmentType, GetAttachmentResponse} from '@/factserver_api/attachment_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from '@/factserver_api/factserver_generic'
import {AttachmentHelper, UploadAttachment} from '@/components/attachment_list'

const confirmation_model = proxy({
    loading: false,
    title: '',
    type: 'info',
})

interface ManageButtonsAttrs {
    attachment_helper: AttachmentHelper
}

export class ManageButtons extends MithrilTsxComponent<ManageButtonsAttrs> {
    pdf_helper = new PDFHelper<ProformaInvoiceRendererAttrs>(proforma_or_invoice_renderer)

    async upload_pdf_to_related_purchase_order(vnode: m.Vnode<any>) {
        const buy_from_account_sales_order_artkey = vnode.attrs.buy_from_account.sales_orders[0]?.artkey
        const buy_from_account_purchase_order_artkey = vnode.attrs.buy_from_account.purchase_orders[0]?.artkey

        if (!buy_from_account_sales_order_artkey || !buy_from_account_purchase_order_artkey) {
            return
        }

        // Upload attachment to purchase order. First get sales order, create pdf and upload.
        const {result} = await api.post<GetFastSalesOrderWithItemsResponse>('sales.core.get_fast_sales_order_with_items', {
            sales_order_artkey: context.data.root_artkey,
            group_similar_items: true,
        }) as any
        // generate PDF and upload.
        const account = $m.accounts.get_account_by_slug(result.account.slug)
        this.pdf_helper.render_base64_encoded({
            sales_order: result as SalesOrder,
            account: PDFHelper._as_account(account) as Account,
            show_article_code: false,
            show_country_of_origin: true,
            show_cbs_code: false,
            cases_or_bottles: CasesOrBottles.cases,
            decimal_locale: 'en',
            show_liters_of_alcohol: false,
            group_similar_items: true,
            proforma_or_invoice: ProformaInvoice.INVOICE,
            show_bottle_lot: false,
        })
        const pdf_file_name = `Invoice - ${result?.invoice_number || ''} - ${account ? account.name() : ''}.pdf`
        const blob = `data:application/pdf;base64,${this.pdf_helper.pdf_as_base64_encoded()}`
        await api.post('attachment.createf_attachment', {
            purchase_order_artkey: buy_from_account_purchase_order_artkey,
            file_name: pdf_file_name,
            attachment_type: AttachmentType.PURCHASE_ORDER,
            file: blob,
        })
        // Set category on uploaded PDF.
        await api.post<GetAttachmentResponse>('attachment.categorize_attachment', {
            attachment_artkey: result.artkey,
            attachment_type: AttachmentType.PURCHASE_ORDER,
            description: 'Invoice buy-from-account',
            category: 'Supplier invoice',
            valid_until: null,
        })
    }

    delivery_buttons(vnode: m.Vnode<any>) {
        const is_reseller = context.data.sales_order.account_slug === AccountSlug.ETR
        const is_exw = is_exw_loendersloot(context.data.sales_order)
        const can_be_sent_to_loendersloot = is_origin_loendersloot(context.data.sales_order) &&
            [CombinedOrderStatus.Confirmed, CombinedOrderStatus.Invoiced].includes(context.data.sales_order.combined_status)

        const is_release = can_be_sent_to_loendersloot && is_exw && !context.data.sales_order.needs_buy_from_account
        const is_outbound = can_be_sent_to_loendersloot && !is_exw && !context.data.sales_order.needs_buy_from_account

        const loendersloot_confirmed = context.data.sales_order.pre_advice_reference || context.data.sales_order.warehouse_reference
        const is_pre_advice_sent = context.data.sales_order.sent_pre_advice_to_loendersloot_on
        const is_release_outbound_sent = context.data.sales_order.sent_to_loendersloot_on
        const has_unstocked_sales_order_items = !context.data.sales_order.all_items_in_stock

        const is_pre_loading_possible = is_outbound && !loendersloot_confirmed && !has_unstocked_sales_order_items && !is_pre_advice_sent
        const is_release_possible = is_release && !loendersloot_confirmed && !has_unstocked_sales_order_items && !is_pre_advice_sent

        const is_pre_advice_possible = can_be_sent_to_loendersloot && (is_exw || is_reseller)

        const elements: m.Child[] = []

        if (context.data.dialog.sent_to_loendersloot) {
            elements.push(<SendToLoenderslootModal
                on_close={() => context.data.dialog.sent_to_loendersloot = false}
                sales_order={context.data.sales_order}
                on_sent_to_loendersloot={async() => {
                    context.data.dialog.sent_to_loendersloot = false
                    await methods.fetch_sales_order_with_items(context.data.root_artkey)
                }}
            />)
        }

        if (context.data.sales_order.delivery_status === DeliveryStatus.Created) {
            elements.push(<Button
                icon="truck"
                onclick={async() => {
                    await api.post('sales.green.confirm_shipment_at_green', {
                        artkey: context.data.root_artkey,
                    })
                    await methods.fetch_sales_order_with_items(context.data.root_artkey)
                }}
                disabled={is_release}
                text="Confirm Green transport"
                type="success"
                variant="context"
            />)
        }

        if (is_release_possible) {
            elements.push(<Button
                icon="fork_lift"
                onclick={() => {
                    context.data.loendersloot_flow = LoenderslootFlow.OUTBOUND
                    context.data.dialog.sent_to_loendersloot = true
                }}
                disabled={!context.data.sales_order.destination_artkey}
                text="Direct release"
                tip="This will release the goods at Loendersloot."
                type="success"
                variant="context"
            />)
        }

        if (is_pre_loading_possible) {
            elements.push(<Button
                icon="fork_lift"
                onclick={() => {
                    context.data.loendersloot_flow = LoenderslootFlow.OUTBOUND
                    context.data.dialog.sent_to_loendersloot = true
                }}
                disabled={!context.data.sales_order.destination_artkey}
                text="Pre-loading"
                tip={() => {
                    if (!context.data.sales_order.destination_artkey) {
                        return 'The order doesn\'t have a destination and cannot be pre-loaded.'
                    }
                    return 'This will pre-load the goods at Loendersloot.'
                }}
                type="success"
                variant="context"
            />)
        }

        if ([
            CombinedOrderStatus.Confirmed,
            CombinedOrderStatus.Invoiced,
        ].includes(context.data.sales_order.combined_status) && context.data.sales_order.pre_advice_reference
        ) {
            elements.push(<Button
                icon="cancel"
                onclick={() => {
                    merge_deep(confirmation_model, {title: 'Confirm the cancelling of the pre-advice', type: 'warning'})

                    const mounted = mount_dialog({
                        body: () => <p>
                            The related buy-from-account Sales order and Purchase order will be cancelled and Loendersloot will be notified of this cancel.
                        </p>,
                        confirm: {
                            action: async() => {
                                mounted.close()
                                await api.post('loendersloot.messaging.send_pre_advice_action_to_loendersloot', {
                                    sales_order_artkey: context.data.root_artkey,
                                    action: 'cancel',
                                })
                                notifier.notify('The pre-advice is cancelled.', 'success')
                                context.data.sales_order.pre_advice_cancelled_on = DateTime.now().toISO()
                            },
                            icon: 'fork_lift',
                            text: 'Cancel Pre-advice',
                        },
                        model: confirmation_model,
                    })
                }}
                disabled={
                    !context.data.sales_order.destination_artkey ||
                    context.data.sales_order.warehouse_reference ||
                    context.data.sales_order.pre_advice_cancelled_on ||
                    context.data.sales_order.pre_advice_confirmed_on
                }
                text="Cancel pre-advice"
                tip={() => {
                    if (!context.data.sales_order.destination_artkey) {
                        return 'The pre-advice doesn\'t have a destination and cannot be cancelled.'
                    }
                    if (context.data.sales_order.warehouse_reference) {
                        return 'The pre-advice already has a warehouse reference and cannot be cancelled.'
                    }
                    if (context.data.sales_order.pre_advice_cancelled_on) {
                        return 'The pre-advice has already been cancelled.'
                    }
                    if (context.data.sales_order.pre_advice_confirmed_on) {
                        return 'The pre-advice has already been confirmed and cannot be cancelled.'
                    }
                    return 'Cancel the pre-advice'
                }}
                type="warning"
                variant="context"
            />)
        }

        if (is_pre_advice_possible) {
            elements.push(
                <Button
                    icon="fork_lift"
                    onclick={() => {
                        context.data.loendersloot_flow = LoenderslootFlow.PRE_ADVICE
                        context.data.dialog.sent_to_loendersloot = true
                    }}
                    disabled={
                        !context.data.sales_order.destination_artkey ||
                        loendersloot_confirmed ||
                        has_unstocked_sales_order_items ||
                        is_release_outbound_sent ||
                        is_pre_advice_sent
                    }
                    text="Pre-advice"
                    tip={() => {
                        if (!context.data.sales_order.destination_artkey) {
                            return 'The order doesn\'t have a destination and cannot be sent to Loendersloot.'
                        }
                        if (loendersloot_confirmed) {
                            return 'Loendersloot already has a pre-advice'
                        }
                        if (has_unstocked_sales_order_items) {
                            return 'The order has unstocked items and cannot be sent to Loendersloot.'
                        }
                        if (is_release_outbound_sent) {
                            return 'The order has already been released to Loendersloot.'
                        }
                        if (is_pre_advice_sent) {
                            return 'The pre-advice has already been sent to Loendersloot.'
                        }
                        if (context.data.sales_order.pre_advice_failure_reason) {
                            return `The pre-advice failed because of: ${context.data.sales_order.pre_advice_failure_reason}`
                        }
                        return 'Send pre-advice to loendersloot'
                    }}
                    type={context.data.sales_order.pre_advice_failure_reason ? 'danger' : 'success'}
                    variant="context"
                />,
            )
        }

        if ([
            CombinedOrderStatus.Confirmed,
            CombinedOrderStatus.Invoiced,
        ].includes(context.data.sales_order.combined_status) && context.data.sales_order.pre_advice_reference
        ) {
            elements.push(<Button
                icon="fork_lift"
                onclick={() => {
                    merge_deep(confirmation_model, {title: 'Confirm the pre-advice', type: 'success'})
                    const message = is_exw ? 'Loendersloot will release the goods.' : 'Loendersloot will start pre-loading the goods.'
                    const mounted = mount_dialog({
                        body: () => <p>
                        The related buy-from-account Sales order and Purchase order will be invoiced/stocked and ${message}`
                        </p>,
                        confirm: {
                            action: async() => {
                                mounted.close()
                                const destination_is_loendersloot = context.data.sales_order.destination_artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
                                const origin_is_loendersloot = context.data.sales_order.origin_name === context.data.sales_order.destination_name
                                const is_exw = context.data.sales_order.incoterm === 'EXW'
                                const is_loendersloot_release = is_exw && origin_is_loendersloot && destination_is_loendersloot

                                if (is_loendersloot_release) {
                                    await api.post('loendersloot.messaging.send_pre_advice_action_to_loendersloot', {
                                        sales_order_artkey: context.data.root_artkey,
                                        action: 'release',
                                    })
                                } else {
                                    await api.post('loendersloot.messaging.send_pre_advice_action_to_loendersloot', {
                                        sales_order_artkey: context.data.root_artkey,
                                        action: 'outbound',
                                    })
                                }

                                await this.upload_pdf_to_related_purchase_order(vnode)
                                if (is_loendersloot_release) {
                                    notifier.notify('The release is requested at Loendersloot.', 'success')
                                } else {
                                    notifier.notify('The outbound is requested at Loendersloot.', 'success')
                                }
                                context.data.sales_order.pre_advice_confirmed_on = DateTime.now().toISO()
                            },
                            icon: 'fork_lift',
                            text: is_exw ? ' Release pre-advice' : ' Pre-loading pre-advice',
                        },
                        model: confirmation_model,
                    })
                }}
                disabled={
                    !context.data.sales_order.destination_artkey ||
                    context.data.sales_order.warehouse_reference ||
                    context.data.sales_order.pre_advice_cancelled_on ||
                    context.data.sales_order.pre_advice_confirmed_on
                }
                text={is_exw ? ' Release pre-advice' : ' Pre-loading pre-advice'}
                tip={() => {
                    if (!context.data.sales_order.destination_artkey) {
                        return `The order doesn't have a destination and cannot be ${is_exw ? 'released' : 'pre-loaded'}.`
                    }
                    if (context.data.sales_order.warehouse_reference) {
                        return `The order already has a warehouse reference and cannot be ${is_exw ? 'released' : 'pre-loaded'}`
                    }

                    if (context.data.sales_order.pre_advice_cancelled_on) {
                        return `The pre-advice has already been cancelled and cannot be ${is_exw ? 'released' : 'pre-loaded'}.`
                    }

                    if (context.data.sales_order.pre_advice_confirmed_on) {
                        return `The pre-advice has already been confirmed and cannot be ${is_exw ? 'released' : 'pre-loaded'}.`
                    }
                }}
                type="success"
                variant="context"
            />)
        }

        return elements
    }

    view(vnode: m.Vnode<any>): m.Children {
        return <div className="c-manage-buttons btn-toolbar">
            <ButtonGroup>
                <ButtonDataCard
                    context={context}
                    collection={vnode.attrs.collection}
                />
                <Button
                    active={context.link_entity_active([
                        EntityType.SOI,
                        EntityType.SOTI,
                        EntityType.SOA,
                        EntityType.SOBI,
                        EntityType.SOCI,
                        EntityType.VOUCHER,
                    ], null)}
                    link={context.link_entity(EntityType.SOI)}
                    link_options={{
                        deactivate: context.data.root_path,
                        replace: true,
                    }}
                    icon="plus"
                    text="Add to Order"
                    tip={() => {
                        if (context.data.sales_order.combined_status === CombinedOrderStatus.Invoiced) {
                            return 'Invoice orders cannot be changed anymore.'
                        } else if (context.data.sales_order.combined_status === CombinedOrderStatus.Cancelled) {
                            return 'Cancelled orders cannot be changed anymore.'
                        } else if (context.data.sales_order.pre_advice_reference) {
                            return 'The order already has a pre-advice and cannot be changed. Cancel the pre-advice before proceeding.'
                        }
                        return 'Add a new product, credit item, voucher or additional item to the order'
                    }}
                    type="info"
                    variant="context"
                />

            </ButtonGroup>

            <ButtonGroup>
                <Button
                    disabled={context.data.photos_is_zipping}
                    icon="image"
                    onclick={() => context.data.dialog.download_photos = true}
                    text="Download photos"
                    tip={() => {
                        if (context.data.photos_is_zipping) {
                            return 'Download is already in progress.'
                        }
                        return 'Download all stock photos and attached product photos'
                    }}
                    type="default"
                    variant="context"
                />
                <Button
                    icon="excel"
                    onclick={async() => {
                        const {result} = await api.post<any>('sales.invoice.export_invoice_to_excel', {
                            sales_order_artkey: context.data.root_artkey,
                        })
                        if (context.data.sales_order) {
                            download_binary_file_from_base64_str(
                                result.excel_binary,
                                `Sales order ${context.data.sales_order.supplier_name} ${
                                    context.data.sales_order.reference
                                } ${formatDate(new Date())}.xlsx`,
                            )
                        }
                    }}
                    text="Export to excel"
                    type="default"
                    variant="context"
                />
                {
                    context.data.sales_order.combined_status === CombinedOrderStatus.Invoiced
                    && Array.isArray(context.data.buy_from_account.purchase_orders)
                    && context.data.buy_from_account.purchase_orders.filter((po) => !po.has_supplier_invoice).length > 0
                    && <Button
                        icon="puzzle_edit_outline"
                        onclick={() => {
                            auto_attach_buy_from_account_supplier_invoices(context.data.root_artkey)
                            // Unable to find a way to await all updates done by function above, so just assume it worked and update has_supplier_invoice to true
                            context.data.buy_from_account.purchase_orders.forEach((po) => po.has_supplier_invoice = true)
                        }}
                        text="Attach BFA files"
                        tip="Attach related supplier invoices to buy from account purchase orders"
                        variant="context"
                    />}
            </ButtonGroup>
            <ButtonGroup>{this.delivery_buttons(vnode)}</ButtonGroup>
            <UploadAttachment attachment_helper={vnode.attrs.attachment_helper}/>

        </div>
    }
}
