import m from 'mithril'
import {to_specs} from '@bitstillery/common/lib/specs'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Country, FieldText, Icon} from '@bitstillery/common/components'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {debounce} from '@bitstillery/common/lib/utils'

interface FieldProductAttrs {
    collection: any
    context: any
    className?: string
    entity: any
    entity_type: any
    linked?: boolean
    locked?: boolean
    model: any
    filter: any
    select_row: any
    tabindex?: number
    debounce_on_search?: number
}

export class FieldProduct extends MithrilTsxComponent<FieldProductAttrs> {

    // Do not directly edit filter.selection, that causes to many queries.
    data = proxy({
        search: '',
    })

    watchers: type_remove_watch_function[] = []

    oncreate(vnode: m.Vnode<FieldProductAttrs>) {
        this.watchers.push(watch(
            this.data,
            'search',
            debounce(vnode.attrs.debounce_on_search || 0, () => vnode.attrs.filter.selection = this.data.search),
        ))
    }

    onremove() {
        this.watchers.forEach((watch) => watch())
    }

    reset_selection(vnode) {
        vnode.attrs.filter.selection = ''
        vnode.attrs.context.reset_entity(vnode.attrs.entity_type)
    }

    view(vnode: m.Vnode<FieldProductAttrs>) {
        return <div
            className='c-field-composed-product field'
            onkeydown={(e) => {
                if (e.key === 'Enter') {
                    if (vnode.attrs.locked) {
                        this.reset_selection(vnode)
                    }
                }
            }}
            tabindex={vnode.attrs.tabindex}
        >
            {!vnode.attrs.linked && <div className="search-container">
                <FieldText
                    autofocus={true}
                    className="js-field-focus"
                    help="Find a product with the desired specs"
                    label="Product name"
                    model={[this.data, 'search']}
                    placeholder="Find a product..."
                    search={{
                        onsuggestion: async(suggestion) => {
                            vnode.attrs.select_row(suggestion)
                        },
                        options: vnode.attrs.collection.state.items.map((i) => {
                            const specs_line = to_specs({
                                bottle_alcohol_percentage: i.alcohol_percentage,
                                bottle_refill_status: i.refill_status,
                                bottle_volume: i.volume,
                                case_number_of_bottles: i.number_of_bottles,
                                case_gift_box_type: i.gift_box_type || '',
                                case_customs_status: i.customs_status,
                                case_tax_label: i.tax_label,
                            })

                            return {
                                label: `${specs_line} - ${i.product_name}`,
                                value: i.product_name,
                                ...i,
                            }
                        }),
                    }}
                    tabindex={vnode.attrs.tabindex}
                />
            </div>}

            {vnode.attrs.linked && <div className="product-container">
                <div className="product">
                    <label>Selected product:</label>
                    <div className='product-header'>
                        <div className="fl fl-g05">{vnode.attrs.context.data.countries_of_origin ?
                            vnode.attrs.context.data.countries_of_origin.map(i => <Country country_code={i.country_of_origin} />) :
                            <Country country_code={vnode.attrs.entity.country_of_origin} />
                        }</div>
                        {vnode.attrs.entity.product_name}
                    </div>
                    <div className='product-specs'>
                        {to_specs({
                            bottle_alcohol_percentage: vnode.attrs.entity.alcohol_percentage,
                            bottle_refill_status: vnode.attrs.entity.refill_status,
                            bottle_volume: vnode.attrs.entity.volume,
                            case_number_of_bottles: vnode.attrs.entity.number_of_bottles,
                            case_gift_box_type: vnode.attrs.entity.gift_box_type || '',
                            case_customs_status: vnode.attrs.entity.customs_status,
                            case_tax_label: vnode.attrs.entity.tax_label,
                        })}
                    </div>
                    <div className="product-details">
                        <Icon name="stock" size="s" type="unset" />Available: {vnode.attrs.entity.number_of_cases_available} ({vnode.attrs.entity.lot})
                    </div>
                    {!!vnode.attrs.entity.cases_per_pallet && <div className="product-details">
                        <Icon name="fork_lift" size="s" type="unset" /> Pallet: {vnode.attrs.entity.cases_per_pallet} cs / Layer: {vnode.attrs.entity.cases_per_pallet_layer} cs
                    </div>}
                </div>

                <Button
                    className="btn-close"
                    disabled={vnode.attrs.locked}
                    icon="close"
                    onclick={() => this.reset_selection(vnode)}
                    tabindex={vnode.attrs.tabindex}
                    type="info"
                    variant="toggle"
                />
            </div>}

            <div className="help">
                {vnode.attrs.locked ? 'Current product is locked' : 'This is the product that will be added to the sales order.'}
            </div>
        </div>
    }
}
