import m from 'mithril'
import {merge_deep, url_query_string} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    ButtonGroup,
    FieldCheckbox,
    FieldSelect,
    FieldText,
    FieldTextArea,
    FieldUpload,
    Icon,
    Stepper,
} from '@bitstillery/common/components'
import {api, logger, notifier} from '@bitstillery/common/app'
import {invalid_fields, invalid_fields_format} from '@bitstillery/common/lib/validation'
import {watch} from '@bitstillery/common/lib/store'

import {ColumnsPicker} from '../components/columns_picker'

import {collection} from '@/market/pricelists/list/lib/collection_spl'
import {context, methods} from '@/market/pricelists/list/lib/context'
import {$m, $s} from '@/app'
import {EntityAlt} from '@/factserver_api/fact2server_api'

export const mappings = {
    cpl: ['Cases per pallet layer', 'Bottles per pallet layer'],
    cpp: ['Cases per pallet', 'Bottles per pallet'],
    product: ['Magic product finder'],
}

export const validation_steps = {
    0: ['default_currency', 'end_date', 'default_incoterm', 'default_incoterm_location', 'start_date', 'relation_artkey'],
    1: ['default_currency', 'end_date', 'default_incoterm', 'default_incoterm_location', 'start_date', 'relation_artkey', 'file'],
}

export class ContextPricelist extends MithrilTsxComponent<any> {

    watchers: any[] = []

    async oninit() {
        this.watchers.push(watch(context.data.stepper, 'selection', async(new_step) => {
            if (new_step >= 1 && !context.data.config_loaded && context.data.file_name) {
                this.load_pricelist_config()
            }
        }))
    }

    onremove() {
        this.watchers.forEach((unwatch: any) => unwatch())
    }

    async load_pricelist_config() {
        const entity = context.data.entities[EntityAlt.SPL]
        context.data.column_mapping = {}

        const {
            result: config,
            status_code,
        } = await api.get(`discover/supplier-price-lists/${entity.artkey}/config?cache_key=${context.data.cache_key}`) as any
        if (status_code > 299) {
            return
        }
        const {
            result: column_types,
            status_code_columns,
        } = await api.get('discover/supplier-price-lists/columns') as any
        if (status_code_columns > 299) {
            return
        }

        merge_deep(context.data, {
            cache_key: config.cache_key, // sets Swift/Excel-related api call cache
            column_mapping: config.column_mapping,
            columns: [...column_types.columns, ...column_types.other_columns].sort((a, b) => a.localeCompare(b)),
            header: config.header,
        })

        this.render_columns()
        context.data.config_loaded = true
        logger.info(`[pricelist] pricelist config loaded (cache: ${config.cache_key}`)
    }

    async process_pricelist() {
        const entity = context.data.entities[EntityAlt.SPL]
        context.data.is_processing_pricelist = true
        logger.info('[pricelist] process pricelist')
        const {result, success} = await api.post(`discover/supplier-price-lists/${entity.artkey}/process`, {
            column_mapping: context.data.column_mapping,
            header: context.data.header,
            store_cpp: context.data.store_cpp_on_case,
            store_cpl: context.data.store_cpl_on_case,
        }, true)
        context.data.is_processing_pricelist = false

        if (success) {
            notifier.notify('Processing pricelist! Refresh the page to see updates.', 'info')
            m.route.set(`/market/pricelists/${entity.artkey}/view/manage${url_query_string({meta: 'true'})}`)
        } else {
            notifier.notify(`Something went wrong. Please notify IT support about this: ${result}`, 'danger')
        }
    }

    render_columns() {
        const columns = [...Object.entries(context.data.header)
            .map(([column_index, column_name]) => {
                // Per-cell status, so we can highlight an entire column.
                return {
                    name: column_name,
                    render: (row) => {
                        return row.candidate[column_index]
                    },
                    type: () => {
                        if (!(column_index in context.data.column_mapping)) return 'hidden'
                        if (context.data.column_mapping[column_index] === '') return 'danger'
                        return 'default'
                    },
                }
            }), {
            className: 'cell-status',
            name: '',
            render: () => null,
            width: 'var(--coll-status-width)',
        }] as any

        context.data.columns_preview.splice(0, context.data.columns_preview.length, ...columns)
    }

    async upsert_pricelist(with_file = false, next_step = false) {
        const entity = context.data.entities[EntityAlt.SPL]
        const data = {
            always_active: context.data.always_active,
            default_currency: entity.default_currency,
            default_customs_status: entity.default_customs_status,
            default_incoterm: entity.default_incoterm,
            default_incoterm_location: entity.default_incoterm_location,
            description: entity.description,
            relation_artkey: entity.relation_artkey,
            start_date: entity.start_date,
            end_date: entity.end_date ? entity.end_date : null,
        }

        if (with_file) {
            // A new file is set when the data is filled; otherwise
            // when only the name is set, the current file is reused.
            context.data.cache_key = ''
            logger.debug('[workflow_pricelist] (re)upload pricelist file; reset cache_key')

            if (context.data.file.data) {
                data.file_holder = {
                    file_contents: context.data.file.data,
                    file_name: context.data.file.name,
                }
            }
        }

        if (entity.artkey) {
            await api.put(`discover/supplier-price-lists/${entity.artkey}`, data)
            logger.info(`[workflow_pricelist] updated pricelist ${entity.artkey}${url_query_string({step: '2'})}`)
            collection.update_item(entity.artkey)
        } else {
            const {result: result_upload} = await api.post('discover/supplier-price-lists', data, true) as any
            merge_deep(entity, {artkey: result_upload.artkey})
            logger.info(`[workflow_pricelist] created new pricelist (${entity.artkey})`)
            collection.reset_query()

        }

        if (with_file) {
            await this.load_pricelist_config()
        }

        // Refresh the collection to show a newly created pricelist.

        if (next_step) {
            context.data.stepper.selection += 1
            m.route.set(`/market/pricelists/list/manage/${EntityAlt.SPL}/${entity.artkey}${url_query_string({step: context.data.stepper.selection})}`)
        } else {
            m.route.set('/market/pricelists/list/manage')
        }
    }

    view() {
        const entity = context.data.entities[EntityAlt.SPL]
        const $v = context.$v[EntityAlt.SPL]

        return <div className="c-workflow-pricelist">
            <div className="workflow">
                <Stepper
                    model={context.data.stepper}
                    options={context.data.stepper.options}
                    tipPlacement="right"
                />
            </div>
            <div className="steps">
                {context.data.stepper.selection === 0 && <div className="form">
                    <FieldText
                        disabled={entity.artkey}
                        help="The supplier of this pricelist"
                        label="Supplier"
                        model={[entity, 'supplier_name']}
                        oninput={async(filter_text: string) => {
                            if (filter_text.length <= 3) return []
                            const {result} = await api.get(`discover/relations?search_terms=${filter_text}`) as any
                            const search_options = result.map((i) => ({value: i.name, label: i.name, ...i}))
                            context.data.search_options.splice(0, context.data.search_options.length, ...search_options)
                        }}
                        search={{
                            linked: !!context.data.relation_artkey,
                            onreset: () => {
                                context.data.relation_artkey = null
                            },
                            onsuggestion: async(suggestion) => {
                                if (suggestion) {
                                    // Use legacy supplier endpoint to get all the supplier fields.
                                    const {result: supplier} = await api.post('suppliers.get_supplier', {artkey: suggestion.artkey}) as any
                                    merge_deep(entity, {
                                        default_currency: supplier.currency,
                                        default_incoterm: supplier.incoterm ?? 'EXW',
                                        default_customs_status: supplier.customs_status,
                                        relation_artkey: suggestion.artkey,
                                    })

                                    const {result: destinations} = await api.get(`discover/relations/${entity.relation_artkey}/locations`) as any
                                    const sorted_destinations = destinations.sort((a, b) => {
                                        if (a.is_primary) return -1
                                        if (b.is_primary) return 1
                                    }).map((i) => {
                                        return {
                                            label: `${i.city} - ${i.country_code}`,
                                            value: `${i.city} - ${i.country_code}`,
                                        }
                                    })

                                    context.data.incoterm_location_options.splice(0, context.data.incoterm_location_options.length, ...sorted_destinations)
                                    // Try to use the primary destination first; otherwise use the supplier city & country.
                                    const destination_primary = destinations.find((i) => i.is_primary)
                                    if (destination_primary) {
                                        entity.default_incoterm_location = `${destination_primary.city} - ${destination_primary.country_code}`
                                    } else {
                                        entity.default_incoterm_location = `${supplier.city.trim()} - ${supplier.country_code}`
                                    }
                                } else {
                                    entity.relation_artkey = null
                                }
                            },
                            options: context.data.search_options,
                        }}
                        validation={$v.relation_artkey}
                    />

                    <FieldCheckbox
                        help="If this is checked, the pricelist will always be active, even if the start or end date is in the past."
                        label="Always active"
                        model={[entity, 'always_active']}
                        onAfterChange={() => {
                            if (context.data.always_active) {
                                context.data.end_date = null
                            }
                        }}
                    />

                    <div className="field-group">
                        <FieldText
                            help="From when should this pricelist be active?"
                            label="Start date"
                            model={[entity, 'start_date']}
                            type="date"
                            validation={$v.start_date}
                        />

                        <FieldText
                            disabled={context.data.always_active}
                            help="Until when should this pricelist be active?"
                            label="End date"
                            model={[entity, 'end_date']}
                            type="date"
                            validation={$v.end_date}
                        />
                    </div>

                    <div className="field-group">
                        <FieldSelect
                            help="Fallback incoterm"
                            label="Default incoterm"
                            model={[entity, 'default_incoterm']}
                            options={$m.data.incoterms.map((i:any) => ({
                                label: i[1],
                                value: i[0],
                            }))}
                            validation={$v.default_incoterm}
                        />

                        {context.data.incoterm_location_options.length ? <FieldSelect
                            label="Default location"
                            model={[entity, 'default_incoterm_location']}
                            options={context.data.incoterm_location_options}
                        /> : <FieldText
                            help="Fallback incoterm location"
                            label="Default location"
                            model={[entity, 'default_incoterm_location']}
                            validation={$v.default_incoterm_location}
                        />}

                    </div>

                    <div className="field-group">
                        <FieldSelect
                            help="The default customs status is a fallback for when no customs status can be found in the price list."
                            label="Default customs status"
                            model={[entity, 'default_customs_status']}
                            options={$m.data.customs_status.map((i) => ({label: i, value: i}))}
                        />

                        <FieldSelect
                            label="Currency"
                            model={[entity, 'default_currency']}
                            options={$s.currencies.all.map((i) => ({label: i, value: i}))}
                            validation={$v.default_currency}
                        />
                    </div>

                    <FieldTextArea
                        help="The pricelist description is used to identify the pricelist in the system."
                        label="Description"
                        model={[entity, 'description']}
                    />
                    <ButtonGroup className="pt-3" type="panel">
                        <Button
                            disabled={(() => {
                                const validation_fields = validation_steps[context.data.stepper.selection]
                                const invalid_fields = validation_fields.map((i) => $v[i]).filter((i) => i && i._invalid)
                                return invalid_fields.length
                            })()}
                            icon="save"
                            text="Save & Close"
                            tip={() => {
                                const validation_fields = validation_steps[context.data.stepper.selection]
                                const fields = invalid_fields($v).filter((i) => validation_fields.includes(i[0]))
                                return invalid_fields_format(fields, 'tip')
                            }}
                            onclick={async() => {
                                await this.upsert_pricelist(false, false)
                            }}
                            type="default"
                        />

                        <Button
                            disabled={(() => {
                                const validation_fields = validation_steps[context.data.stepper.selection]
                                const invalid_fields = validation_fields.map((i) => $v[i]).filter((i) => i && i._invalid)
                                return invalid_fields.length
                            })()}
                            icon="arrow_right_cicle_outline"
                            onclick={() => {
                                // We already have a file; and the pricelist status doesn't allow us to
                                // set a new one. Instead, we redirect directly to the SPLI/Sourceline view.
                                if (!entity.started_processing_on) {
                                    this.upsert_pricelist(false, true)
                                } else {

                                    m.route.set(`/market/pricelists/${entity.artkey}/view/manage${url_query_string({step: '1'})}`)
                                }
                            }}
                            text={(() => {
                                if (!entity.started_processing_on) {
                                    return 'Save & Import Excel'
                                }
                                return 'Manage Products'
                            })()}
                            tip={() => {
                                const validation_fields = validation_steps[context.data.stepper.selection]
                                const fields = invalid_fields($v).filter((i) => validation_fields.includes(i[0]))
                                return invalid_fields_format(fields, 'tip')
                            }}
                            type={!entity.started_processing_on ? 'success' : 'info'}
                        />
                    </ButtonGroup>
                </div>}

                {context.data.stepper.selection === 1 && <div className="form">
                    <FieldUpload
                        accept="application/xml,.xls,.xlsx"
                        help="Upload an Excel pricelist file"
                        label="Pricelist Excel"
                        model={context.data.file}
                        placeholder={'Ctrl-v or drag your pricelist here'}
                        type="file"
                        validation={$v.file}
                    />

                    <ButtonGroup className="pt-3" type="panel">
                        <Button
                            disabled={(() => {
                                const validation_fields = validation_steps[context.data.stepper.selection]
                                const invalid_fields = validation_fields.map((i) => $v[i]).filter((i) => i && i._invalid)
                                return invalid_fields.length
                            })()}
                            icon="arrow_right_cicle_outline"
                            text="Save & Adjust Columns"
                            tip={() => {
                                const validation_fields = validation_steps[context.data.stepper.selection]
                                const fields = invalid_fields($v).filter((i) => validation_fields.includes(i[0]))
                                return invalid_fields_format(fields, 'tip')
                            }}
                            onclick={async() => {
                                await this.upsert_pricelist(true, true)
                            }}
                            type="success"
                        />
                    </ButtonGroup>
                </div>}

                {context.data.stepper.selection === 2 && (() => {
                    const product_is_mapped = methods.column_is_mapped('product')
                    const columns_invalid = methods.columns_are_invalid()

                    return <div className="form">
                        <ColumnsPicker type="list"/>
                        <div className="setup-stats">
                            <div className="checklist">
                                <div className="check">
                                    <Icon
                                        name={!columns_invalid ? 'checked' : 'warning'}
                                        tip="There should not be duplicate column types in the selection"
                                        type={!columns_invalid ? 'success' : 'warning'}
                                    />
                                    <span>Column layout is invalid</span>
                                </div>
                                <div className="check">
                                    <Icon
                                        name={product_is_mapped ? 'checked' : 'warning'}
                                        tip="Product column selected"
                                        type={product_is_mapped ? 'success' : 'warning'}
                                    />
                                    <span>Product column selected</span>
                                </div>
                            </div>
                        </div>

                        <ButtonGroup className="pt-3" type="panel">
                            <Button
                                disabled={columns_invalid || !context.data.candidates_loaded || context.data.is_processing_pricelist}
                                onclick={() => {
                                    this.process_pricelist()
                                }}
                                icon="cog"
                                text="Import Products"
                                type="success"
                            />
                        </ButtonGroup>
                    </div>
                })()}
            </div>
        </div>
    }
}
